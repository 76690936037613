"use strict";



/**
 * @constructor Wounder
 *
 */
const Wounder = function() {
	this.damage = 0;
}
//Wounder.prototype = {};




module.exports = Wounder;