"use strict";



/**
 * @constructor Healer
 *
 */
const Healer = function() {
	this.damage = -1;
}
//Healer.prototype = {};




module.exports = Healer;